@import 'src/utils/utils';

.list {
  @include zero;
  background-color: $Main-2;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 24px;
  padding-right: 16px;
  padding-bottom: 30px;
  padding-left: 16px;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xl) {
    margin-top: 60px;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
  }
  @media(min-width: $xxl) {
    @include box;
  }

  a {
    @include Text-14-reg;
    position: relative;
    display: inline-block;
    padding-left: 22px;
    text-decoration: none;
    color: $Corporate-2;

    @media(min-width: $lg) {
      padding-left: 28px;
    }
    @media(min-width: $xl) {
      @include Text-16-reg;
    } 

    &:hover {
      @media(min-width: $lg) {
        text-decoration: underline;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/link.svg');
      background-size: contain;
      background-position: center;
      content: '';

      @media(min-width: $lg) {
        top: 2px;
        width: 24px;
        height: 24px;
      }
    }
  }

  p {
    @include zero;
    @include Text-14-reg;
    margin-top: 15px;
    margin-bottom: 15px;
    color: $Main-1;

    @media(min-width: $xl) {
      @include Text-16-reg;
    }
  }

  ol,
  ul {
    @include zero;
    list-style: none;
    counter-reset: li;

    & > li {
      @include zero;
      @include Text-16-bold;
      position: relative;
      margin-top: 15px;
      color: $Main-1;

      @media(min-width: $md) {
        padding-left: 35px;
      }
      @media(min-width: $xl) {
        @include Text-18-bold;
        margin-top: 40px;
        padding-left: 40px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:before {
        @include Text-16-bold;
        color: $Main-1;
        content: counters(li,".") ". ";
        counter-increment: li;

        @media(min-width: $md) {
          position: absolute;
          left: 0;
        }
        @media(min-width: $xl) {
          @include Text-18-bold;
        }
      }

      ol,
      ul {

        & > li {
          @include Text-14-reg;

          @media(min-width: $md) {
            display: block;
            padding-left: 50px;
          }
          @media(min-width: $xl) {
            @include Text-16-reg;
            margin-top: 20px;
          }

          &:first-child {
            margin-top: 15px;

            @media(min-width: $xl) {
              margin-top: 20px;
            }
          }
  
          &:before {
            @include Text-14-reg;
  
            @media(min-width: $xl) {
              @include Text-16-reg;
            }
          }
        }
      }
    }
  }

  ul {

    li {
      margin-top: 10px !important;

      &:before {
        margin-right: 10px;
        content: '—';
      }
    }
  }
}